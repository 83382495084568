import * as React from 'react';
import { Formik, FormikErrors, FormikProps } from 'formik';
import {
  Business,
  Currency,
  Profile,
  Purchase,
} from '@humancollective/seedz-shared';

import * as Form from '../Form';

import { createId, DateUtility } from '../../utilities';
import {
  AMERICAN_STATES,
  CANADIAN_PROVINCES,
  SUPPORTED_COUNTRIES,
} from '../../config';

const purchaseOptions: { label: string; value: number }[] = [
  { label: 'Select an option:', value: 0 },
  { label: '100 Credits ($500)', value: 100 },
  { label: '220 Credits ($1000)', value: 220 },
  { label: '650 Credits ($2500)', value: 650 },
];

interface PaymentFormProps {
  business: Business;
  profile: Profile;
  pricing: {
    subtotal: number;
    tax: number;
    total: number;
    currency: string;
  };
  onSubmit: (values: Purchase, formikBag: any) => void;
  validate: (values?: any) => Promise<FormikErrors<any>>;
  isFetching: boolean;
}

const PaymentForm: React.FunctionComponent<PaymentFormProps> = ({
  business,
  profile,
  onSubmit,
  validate,
  pricing,
  isFetching,
}) => {
  const initialValues: Purchase = {
    id: createId(),
    billing: {
      name: profile.name,
      businessName: business.name,
      email: '',
      phoneNumber: '',
      address: {
        streetAddress: '',
        locality: '',
        postalCode: '',
        region: '',
        country: '',
        ...business.address,
      },
    },
    user: profile.id,
    numberOfCredits: 0,
    stripeToken: '',
    datePurchased: DateUtility.now(),
    price: {
      total: 0,
      tax: 0,
      subtotal: 0,
      currency: Currency.USD,
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      isInitialValid={false}
    >
      {({
        isSubmitting,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        validateForm,
        setErrors,
        setTouched,
        isValid,
      }: FormikProps<Purchase>) => (
        <Form.StyledForm
          onSubmit={e => {
            e.preventDefault();
            setFieldValue('price', pricing);
            validateForm();
            handleSubmit();
          }}
        >
          <h2>Purchase Credits</h2>
          <Form.FieldSelect
            name="numberOfCredits"
            title="Credits"
            options={purchaseOptions}
            required
          />
          <Form.FieldInput
            name="billing.name"
            title="Name"
            placeholder="John Smith"
            autoComplete="name"
            required
          />
          <Form.FieldInput
            name="billing.businessName"
            title="Business Name"
            placeholder="Acme Inc"
          />
          <Form.FieldInput
            name="billing.email"
            title="Email Address"
            placeholder="john@acmeinc.com"
            autoComplete="email"
            required
          />
          <Form.FieldInput
            name="billing.phoneNumber"
            title="Phone Number"
            placeholder="999-9999-9999"
            autoComplete="tel"
          />
          <Form.FieldInput
            name="billing.address.streetAddress"
            title="Street Address"
            placeholder="123 Business St."
            autoComplete="street-address"
            required
          />
          <Form.FieldGroup>
            <Form.FieldGroupItem>
              <Form.FieldInput
                name="billing.address.locality"
                title="City"
                placeholder="City"
                autoComplete="address-level2"
                required
              />
            </Form.FieldGroupItem>
            <Form.FieldGroupItem>
              <Form.FieldInput
                name="billing.address.postalCode"
                title="Postal Code / ZIP"
                placeholder="X0X 0X0"
                autoComplete="postal-code"
                required
              />
            </Form.FieldGroupItem>
          </Form.FieldGroup>
          <Form.FieldGroup>
            <Form.FieldGroupItem>
              <Form.FieldSelect
                name="billing.address.region"
                title="Province / State"
                placeholder="Select a Province / State:"
                autoComplete="address-level1"
                options={[...AMERICAN_STATES, ...CANADIAN_PROVINCES]}
                required
              />
            </Form.FieldGroupItem>
            <Form.FieldGroupItem>
              <Form.FieldSelect
                name="billing.address.country"
                title="Country"
                placeholder="Select a Country:"
                autoComplete="country"
                options={SUPPORTED_COUNTRIES}
                required
              />
            </Form.FieldGroupItem>
          </Form.FieldGroup>
          <Form.FieldPayment
            title="Payment"
            errors={errors}
            setErrors={setErrors}
            touched={touched}
            setTouched={setTouched}
            onChange={stripeTokenId => {
              setFieldValue('stripeToken', stripeTokenId);
            }}
          />
          <Form.SubmitButton
            label="Purchase"
            disabled={isSubmitting || isFetching || !isValid}
          />
        </Form.StyledForm>
      )}
    </Formik>
  );
};

export default PaymentForm;
