import { Address } from '@humancollective/seedz-shared';
import * as yup from 'yup';

const addressValidationSchema = yup.object().shape<Address>({
  region: yup.string().required('Please provide a province / state.'),
  locality: yup.string().required('Please provide a city.'),
  country: yup.string().required('Please provide a country.'),
  postalCode: yup.string().required('Please provide a postal code.'),
  streetAddress: yup.string().required('Please provide a street address.'),
});

export default addressValidationSchema;
