import React from 'react';
import { Formik } from 'formik';
import { Button } from '@blueprintjs/core';
import { Address, Position } from '@humancollective/seedz-shared';

import * as Form from '../../Form';
import {
  AMERICAN_STATES,
  CANADIAN_PROVINCES,
  SUPPORTED_COUNTRIES,
} from '../../../config';
import addressValidationSchema from '../../Forms/validation/address';
import geocode from '../../../utilities/geocode';

interface EditAddressModalProps {
  initialValues: Address;
  onSubmit: ({
    address,
    position,
  }: {
    address: Address;
    position: Position;
  }) => void;
}

export const EditAddressModal: React.FunctionComponent<
  EditAddressModalProps
> = ({ initialValues, onSubmit }) => {
  const getPosition = async (values: Address) => {
    const addressString = Object.values(values).join(', ');
    try {
      const position = await geocode(addressString);
      return position;
    } catch (error) {
      console.error(error);
    }
    return {
      latitude: 0,
      longitude: 0,
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addressValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const position = await getPosition(values);
        onSubmit({ position, address: values });
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form.StyledForm className="edit-address-modal__form">
          <Form.FieldInput
            name="streetAddress"
            title="Street Address"
            placeholder="123 Business St."
            autoComplete="street-address"
          />
          <Form.FieldInput
            name="locality"
            title="City"
            placeholder="City"
            autoComplete="address-level2"
          />
          <Form.FieldInput
            name="postalCode"
            title="Postal Code / ZIP"
            placeholder="X0X 0X0"
            autoComplete="postal-code"
          />
          <Form.FieldSelect
            name="region"
            title="Province / State"
            placeholder="Select a Province / State:"
            autoComplete="address-level1"
            options={[...AMERICAN_STATES, ...CANADIAN_PROVINCES]}
          />
          <Form.FieldSelect
            name="country"
            title="Country"
            placeholder="Select a Country:"
            autoComplete="country"
            options={SUPPORTED_COUNTRIES}
          />
          <Button
            icon="floppy-disk"
            text="Save Details"
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
          />
        </Form.StyledForm>
      )}
    </Formik>
  );
};
