import React from 'react';
import { Menu as BPMenu, Navbar, Popover, Position } from '@blueprintjs/core';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {
  BusinessesContext,
  FirebaseContext,
  IsUserAdminContext,
  ProfileContext,
} from '../../contexts';
import logoImage from '../../assets/images/logo/logo-green.svg';
import { setUserBusiness } from '../../utilities/firebase';
import { Profile } from '../../types';
import { Imgix } from '../../utilities';
import { Routes } from '../../config';

const StyledNavigationBar = styled(Navbar)`
  width: 100vw;

  .navigation-bar__avatar {
    height: 36px;
    width: 36px;
    margin-left: 12px;
    cursor: pointer;
    border-radius: 50%;
  }

  .navigation-bar__link {
    padding: 0 12px;
    color: black;
  }
`;

const StyledNavigationLogo = styled.div.attrs({ image: logoImage })`
  background-image: ${({ image }) => `url(${image})`};
  height: 30px;
  width: 110px;
  background-size: contain;
  background-repeat: no-repeat;
  flex-grow: 1;
`;

interface UserBusinessDropDownProps {
  profile: Profile | null;
}
const UserBusinessDropdown: React.FunctionComponent<
  UserBusinessDropDownProps
> = ({ profile }) => {
  const isUserAdmin = React.useContext(IsUserAdminContext);
  const businesses = React.useContext(BusinessesContext);

  const userBusiness = businesses.find(
    b => !!profile && profile.business === b.id
  );

  if (!profile) return null;

  if (isUserAdmin) {
    return (
      <div className="bp3-select">
        <select
          value={profile.business}
          onChange={e => setUserBusiness(e.target.value)}
        >
          <option value="ALL">All Businesses</option>
          {businesses.map(b => (
            <option key={b.id} value={b.id}>
              {b.name}
            </option>
          ))}
        </select>
      </div>
    );
  }

  if (userBusiness) {
    return (
      <Link className="navigation-bar__link bp3-minimal" to={Routes.BUSINESS}>
        {userBusiness.name}
      </Link>
    );
  }

  return null;
};

const Menu: React.FunctionComponent<{
  showBusinessOptions: boolean;
}> = ({ showBusinessOptions }) => (
  <BPMenu>
    {showBusinessOptions && (
      <>
        <Link to="/business" className="bp3-menu-item bp3-icon-office">
          Edit Business
        </Link>
        <Link to="/payment" className="bp3-menu-item bp3-icon-credit-card">
          Buy Credits
        </Link>
      </>
    )}
    <BPMenu.Divider />
    <Link to="/logout" className="bp3-menu-item bp3-icon-log-out">
      Log Out
    </Link>
  </BPMenu>
);

const Navigation: React.FunctionComponent = () => {
  const firebaseContext = React.useContext(FirebaseContext);
  const profile = React.useContext(ProfileContext);

  const authenticated = firebaseContext.isLoggedIn;
  let avatarUrl = '/utils/seedz-icon.png';

  if (authenticated && profile) {
    const { images } = profile;
    avatarUrl = Imgix.createUrl(images.avatar || avatarUrl, {
      height: 36,
      width: 36,
      auto: 'COMPRESS',
      dpr: '2',
    });
  }

  return (
    <StyledNavigationBar>
      <Navbar.Group>
        <Link to="/">
          <StyledNavigationLogo />
        </Link>
        <Navbar.Divider />
        <Link to="/map" className="navigation-bar__link bp3-minimal">
          Map
        </Link>
      </Navbar.Group>
      <Navbar.Group align="right">
        {authenticated ? (
          <>
            <UserBusinessDropdown profile={profile} />
            {avatarUrl && (
              <Popover
                content={
                  <Menu
                    showBusinessOptions={
                      !!profile && profile.business !== 'ALL'
                    }
                  />
                }
                position={Position.BOTTOM}
              >
                <img className="navigation-bar__avatar" src={avatarUrl} />
              </Popover>
            )}
          </>
        ) : (
          <Link className="nav-link" to="/login">
            Log In
          </Link>
        )}
      </Navbar.Group>
    </StyledNavigationBar>
  );
};

export default Navigation;
