import * as React from 'react';
import styled from 'styled-components';
import { Breadcrumbs, IBreadcrumbProps } from '@blueprintjs/core';

export const StyledForm = styled.form`
  max-width: 500px;
  width: 100%;

  .form-breadcrumbs {
    padding: 12px;
    background: white;
    border-bottom: 1px solid #d4d6d9;
    flex: 0;
  }

  .form-content {
    padding: 20px;
  }

  .form__input,
  textarea {
    box-sizing: content-box;
    height: 20px;
    color: #32325d;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    padding: 10px 12px;
    font-size: 13px;
    width: calc(100% - 26px) !important;
    margin-top: 4px;

    &:focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    &.is--invalid {
      border-color: #8c4799;
    }
  }

  textarea {
    line-height: 2;
    height: 50px;
    resize: none;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: #2b9200;
    margin-top: 24px;
    margin-bottom: 8px;
    display: block;
  }

  h3 {
    font-weight: 400;
    margin-top: 45px;
    margin-bottom: 0;
    &.is--bottom-padded {
      margin-bottom: 18px;
    }
  }

  .form__error {
    color: #8c4799;
    font-weight: 600;
    font-size: 13px;
    margin-top: 8px;
  }

  .form__summary--mobile {
    display: none;
    @media (max-width: 955px) {
      display: block;
    }
  }

  .form__field-group {
    display: flex;
    @media (max-width: 330px) {
      display: block;
    }
  }

  .form__field-group__item {
    flex-grow: 1;
    margin-right: 12px;
    &:last-of-type {
      margin-right: 0;
    }
    @media (max-width: 330px) {
      margin-right: 0;
    }
  }

  .form__button {
    margin-top: 18px;
    background-color: #8d1aa0;
    width: 100%;
    height: 40px;
    border: none;
    color: white;
    cursor: pointer;
    transition: all 300ms ease-out;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #93399a;
    box-shadow: 0 1px 2px #51105636;
    padding: 12px;
    letter-spacing: 0.7px;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;

    :hover {
      background-color: #b338bd;
      transform: translateY(-1px);
      box-shadow: 0 3px 4px #51105636;
    }

    :active {
      background-color: #6a1771;
      transform: translateY(1px);
      box-shadow: none;
    }

    :disabled {
      background-color: #d2d2d2;
      border-color: darkgrey;
      color: #888888;
    }
  }

  .form__submit-button {
    margin-top: 18px;
    background-color: #2a9200;
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
    transition: all 300ms ease-out;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #2a9200;
    padding: 12px;
    letter-spacing: 0.7px;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;

    :hover {
      background-color: #2a9200;
      transform: translateY(-1px);
    }

    :active {
      background-color: #2a9200;
      transform: translateY(1px);
      box-shadow: none;
    }

    :disabled {
      background-color: #d2d2d2;
      border-color: darkgrey;
      color: #888888;
    }
  }

  .form__error {
    color: #e91e63;
    font-size: 14px;
  }
`;

interface FormProps {
  breadcrumbs?: IBreadcrumbProps[];
  onSubmit: () => void;
}
const Form: React.FunctionComponent<FormProps> = ({
  children,
  breadcrumbs,
  onSubmit,
}) => (
  <StyledForm onSubmit={onSubmit}>
    {breadcrumbs && (
      <div className="form-breadcrumbs">
        <Breadcrumbs items={breadcrumbs} />
      </div>
    )}
    <div className="form-content">{children}</div>
  </StyledForm>
);

// TODO rename this
export { Form as FormRoot };
