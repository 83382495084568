import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FormGroup } from 'react-bootstrap';

type FieldInputProps = {
  name: string;
  title?: string;
  placeholder?: string;
  required?: boolean;
  autoComplete?: string;
  type?: string;
};

export const FieldInput = ({
  name,
  title,
  placeholder,
  required,
  autoComplete,
  type,
  ...rest
}: FieldInputProps) => (
  <FormGroup>
    {!!title && (
      <label>
        {title}
        {!!required && <span className="bp3-text-muted"> (required)</span>}
      </label>
    )}
    <div className="bp3-form-content">
      <div className="bp3-input-group">
        <Field
          className="bp3-input form__input"
          name={name}
          placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          type={type}
          {...rest}
        />
      </div>
    </div>
    <div className="form__error">
      <ErrorMessage
        name={name}
        component="div"
        className="bp3-form-helper-text"
      />
    </div>
  </FormGroup>
);
