import * as yup from 'yup';

import { AddressValidation } from '.';

import { Purchase } from '@humancollective/seedz-shared';

const validationSchema = yup.object().shape<Purchase>({
  billing: yup.object().shape({
    name: yup.string().required('Please provide a name'),
    businessName: yup.string(),
    phoneNumber: yup.string().required('Please provide a phone number'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Please provide an email'),
    address: AddressValidation,
  }),
  numberOfCredits: yup
    .number()
    .min(1, 'The minimum amount of credits you can purchase is 1'),
  stripeToken: yup.string().required('Please provide a payment method.'),
  id: yup.string(),
  datePurchased: yup.object(),
  user: yup.string(),
  price: yup.object(),
});

export default validationSchema;
