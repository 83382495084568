import { Position } from '../types';

export default async (address: string) =>
  new Promise<Position>((resolve, reject) => {
    if (!address) return resolve({ latitude: 0, longitude: 0 });
    if (!window.google || !window.google.maps) reject('maps sdk not found');
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results: any, status: string) => {
      if (status !== 'OK') return reject(status);
      if (!results || !results.length) {
        return resolve({ latitude: 0, longitude: 0 });
      }
      const latitude = results[0].geometry.location.lat() || 0;
      const longitude = results[0].geometry.location.lng() || 0;
      return resolve({ latitude, longitude });
    });
  });
