import * as yup from 'yup';
import { Position, Zone } from '@humancollective/seedz-shared';

interface ZoneOptions {
  creditMultiplier?: number;
  enabledBusinesses?: string[];
  disableMarkers?: boolean;
}

const zoneValidation = yup.object().shape<Zone>({
  live: yup.boolean(),
  id: yup.string(),
  bounds: yup
    .array(yup.object<Position>())
    .min(3, 'Zone bounds require 3 points'),
  options: yup.object().shape<ZoneOptions>({
    creditMultiplier: yup
      .number()
      .min(0)
      .max(0.9, 'The max rate increase is 0.9'),
    enabledBusinesses: yup.array(yup.string()),
    disableMarkers: yup.boolean(),
  }),
});

export default zoneValidation;
