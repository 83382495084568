import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { FormikErrors, FormikTouched } from 'formik';

interface FieldPaymentProps {
  stripe?: any;
  title: string;
  onChange: (isComplete: string) => void;
  errors: any;
  setErrors: (errors: FormikErrors<any>) => void;
  touched: FormikTouched<any>;
  setTouched: (touched: FormikTouched<any>) => void;
}

class FieldPaymentNoStripe extends Component<FieldPaymentProps> {
  public render = () => {
    const {
      stripe,
      title,
      onChange,
      errors,
      setErrors,
      touched,
      setTouched,
    } = this.props;
    const error =
      errors.stripeCharge ||
      (touched.stripe && (errors.stripeToken || errors.stripe));
    return (
      <div>
        <label>{title}</label>
        <CardElement
          className="form__input"
          onChange={async o => {
            if (o.error) {
              setErrors({ stripeToken: o.error.message });
            } else if (o.complete) {
              const { token } = await stripe.createToken({ name: 'Name' });
              onChange(token.id);
            } else {
              onChange('');
            }
          }}
          onBlur={() => setTouched({ stripe: true })}
        />
        {error && <div className="form__error">{error}</div>}
      </div>
    );
  };
}

export const FieldPayment = injectStripe(FieldPaymentNoStripe);
