import React from 'react';
import { Button, Card } from '@blueprintjs/core';
import styled from 'styled-components';

import { UIContext } from '../../../contexts';
import { ContactDetails, EditContactModal } from '../../Modal/EditContactModal';

const StyledFieldContact = styled(Card)`
  margin-top: 24px;

  > label {
    margin-top: 0;
  }

  .field-contact__value {
    margin-bottom: 12px;
  }
`;

interface FieldContactProps {
  title: string;
  value?: ContactDetails;
  error?: string;
  onChange: (value: ContactDetails) => void;
}

const valueDefs = [
  {
    name: 'website',
    label: 'Website',
    getLink: (val: string) => (
      <a href={`${val}`} target="_blank">
        {val}
      </a>
    ),
  },
  {
    name: 'phone',
    label: 'Phone',
    getLink: (val: string) => (
      <a href={`tel:${val}`} target="_blank">
        {val}
      </a>
    ),
  },
  {
    name: 'email',
    label: 'Email',
    getLink: (val: string) => (
      <a href={`mailto:${val}`} target="_blank">
        {val}
      </a>
    ),
  },
  {
    name: 'facebookUrl',
    label: 'Facebook',
    getLink: (val: string) => (
      <a href={`${val}`} target="_blank">
        {val}
      </a>
    ),
  },
  {
    name: 'instagramUrl',
    label: 'Instagram',
    getLink: (val: string) => (
      <a href={`${val}`} target="_blank">
        {val}
      </a>
    ),
  },
  {
    name: 'twitterUrl',
    label: 'Twitter',
    getLink: (val: string) => (
      <a href={`${val}`} target="_blank">
        {val}
      </a>
    ),
  },
];

export const FieldContact: React.FunctionComponent<FieldContactProps> = ({
  title,
  value,
  onChange,
  error,
}) => {
  const ui = React.useContext(UIContext);

  const initialValues: ContactDetails = {
    website: '',
    phone: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    email: '',
    ...value,
  };

  return (
    <StyledFieldContact>
      <label>{title}</label>
      {value && (
        <div>
          {valueDefs.map(def =>
            value[def.name] ? (
              <div key={def.name} className="field-contact__value">
                <strong>{def.label}:</strong> {def.getLink(value[def.name])}
              </div>
            ) : null
          )}
        </div>
      )}
      <Button
        text="Change Contact Details"
        onClick={() => {
          ui.modal.show({
            heading: 'Change Contact Details',
            body: (
              <EditContactModal
                initialValues={initialValues}
                onSubmit={nextValue => {
                  onChange(nextValue);
                  ui.modal.dismiss();
                }}
              />
            ),
          });
        }}
      />
      {error && <div className="form__error">{error}</div>}
    </StyledFieldContact>
  );
};
