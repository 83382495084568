import React from 'react';
import { Formik } from 'formik';
import { Button } from '@blueprintjs/core';

import * as Form from '../../Form';

export interface ContactDetails {
  website: string;
  phone: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  email: string;
}

interface EditContactModalProps {
  initialValues: ContactDetails;
  onSubmit: (values: ContactDetails) => void;
}

export const EditContactModal: React.FunctionComponent<
  EditContactModalProps
> = ({ initialValues, onSubmit }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit(values);
      setSubmitting(false);
    }}
  >
    {({ isSubmitting, handleSubmit }) => (
      <Form.StyledForm className="edit-contact-modal__form">
        <Form.FieldInput
          name="website"
          title="Website Link"
          placeholder="https://website.com"
        />
        <Form.FieldInput
          name="phone"
          title="Phone Number"
          placeholder="999-999-9999"
        />
        <Form.FieldInput
          name="facebookUrl"
          title="Facebook Link"
          placeholder="https://facebook.com/your-page"
        />
        <Form.FieldInput
          name="instagramUrl"
          title="Instagram Link"
          placeholder="https://instagram.com/your-page"
        />
        <Form.FieldInput
          name="twitterUrl"
          title="Twitter Link"
          placeholder="https://twitter.com/your-page"
        />
        <Form.FieldInput
          name="email"
          title="Email Address"
          placeholder="email@address.com"
        />
        <Button
          icon="floppy-disk"
          text="Save Details"
          disabled={isSubmitting}
          onClick={() => handleSubmit()}
        />
      </Form.StyledForm>
    )}
  </Formik>
);
