import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@blueprintjs/core';

const StyledLoading = styled.div<{ height: string; width: string }>`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.height};
  width: ${props => props.width};
`;

interface Loading {
  height?: string; // Pass in 100vh, 100px, 100%, etc;
  width?: string;
}

const Loading: React.FunctionComponent<Loading> = ({
  height = '100vh',
  width = '100vw',
}) => (
  <StyledLoading height={height} width={width}>
    <Spinner />
  </StyledLoading>
);

export default Loading;
