import React from 'react';
import styled from 'styled-components';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { UserBusinessContext } from '../../contexts';

const StyledPaymentSummary = styled.div`
  background-color: #f4f8fa;
  margin: 44px;
  flex: 1;
  background-color: #f4f8fa;
  padding: 44px;
  height: 100%;

  table {
    width: 100%;
    margin-top: 24px;
  }
`;

interface PaymentSummaryProps {
  pricing?: {
    subtotal: number;
    tax: number;
    total: number;
    currency: string;
  };
  isFetching: boolean | null;
}

const PaymentSummary: React.FunctionComponent<PaymentSummaryProps> = ({
  pricing,
  isFetching,
}) => {
  const userBusiness = React.useContext(UserBusinessContext);
  return (
    <StyledPaymentSummary>
      <NonIdealState icon={IconNames.DOLLAR} title="Payment" />
      {userBusiness && (
        <table className="bp3-html-table">
          <thead>
            <tr>
              <th colSpan={2}>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Credits Remaining</td>
              <td style={{ textAlign: 'right' }}>{userBusiness.credits}</td>
            </tr>
          </tbody>
        </table>
      )}
      {pricing && !isFetching && (
        <table className="bp3-html-table">
          <thead>
            <tr>
              <th colSpan={2}>Purchase Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Subtotal</td>
              <td style={{ textAlign: 'right' }}>
                {`$${pricing.subtotal} ${pricing.currency}`}
              </td>
            </tr>
            <tr>
              <td>Tax</td>
              <td style={{ textAlign: 'right' }}>
                {`$${pricing.tax} ${pricing.currency}`}
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td style={{ textAlign: 'right' }}>
                {`$${pricing.total} ${pricing.currency}`}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {isFetching && <Spinner />}
    </StyledPaymentSummary>
  );
};

export default PaymentSummary;
