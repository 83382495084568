import React from 'react';
import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components';

export const StyledFieldSelect = styled.div`
  select {
    cursor: pointer;
    appearance: none;
  }

  .bp3-select {
    width: 100%;
  }
`;

type FieldSelectProps = {
  name: string;
  title?: string;
  placeholder?: string;
  required?: boolean;
  autoComplete?: string;
  options: { label: string; value: string | number }[];
};

export const FieldSelect = ({
  name,
  title,
  placeholder,
  required,
  autoComplete,
  options,
}: FieldSelectProps) => (
  <StyledFieldSelect>
    {!!title && <label>{title}</label>}
    <div className="bp3-select">
      <Field
        name={name}
        required={required}
        autoComplete={autoComplete}
        component="select"
      >
        {!!placeholder && <option>{placeholder}</option>}
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </Field>
    </div>
    <div className="form__error">
      <ErrorMessage name={name} component="span" />
    </div>
  </StyledFieldSelect>
);
