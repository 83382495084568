import React from 'react';
import styled from 'styled-components';
import { Elements } from 'react-stripe-elements';
import set from 'lodash/set';
import debounce from 'debounce-async';

import PaymentForm from './PaymentForm';
import PaymentSummary from './PaymentSummary';

import { ProfileContext, UIContext, UserBusinessContext } from '../../contexts';
import Loading from '../Loading';
import { Purchase } from '@humancollective/seedz-shared';
import { errorToast, successToast } from '../../contexts/UI/Toasts';
import { Routes } from '../../config';
import { navigate } from 'gatsby';
import { calculateTotal, purchaseCredits } from '../../utilities/api';
import { PurchaseCreditsValidation } from '../Forms/validation';

const StyledPayment = styled.div`
  display: flex;
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding: 44px;
`;

const debouncedCalculateTotal = debounce(calculateTotal, 500);

const Payment: React.FunctionComponent = () => {
  const profile = React.useContext(ProfileContext);
  const userBusiness = React.useContext(UserBusinessContext);
  const { showToast } = React.useContext(UIContext);
  const [pricing, setPricing] = React.useState(null);
  const [orderHash, setOrderHash] = React.useState('');
  const [isFetching, setIsFetching] = React.useState(null as boolean | null);

  const submitPayment = async (purchase: Purchase, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await purchaseCredits({
        purchase,
        businessId: userBusiness.id,
      });
      showToast({
        ...successToast,
        message: `Succesfully purchased ${purchase.numberOfCredits} credits`,
      });
      navigate(Routes.BUSINESS);
    } catch (error) {
      if (error !== 'canceled') {
        showToast({ ...errorToast, message: 'An error occured' });
        setSubmitting(false);
      }
    }
  };

  const validate = async (purchase: Purchase) => {
    const errors = {} as { [fieldName: string]: string };

    if (!purchase.stripeToken) errors.stripe = 'Payment method is required';

    try {
      await PurchaseCreditsValidation.validate(purchase, { abortEarly: false });
    } catch (fieldErrors) {
      fieldErrors.inner.forEach(({ path, message }: any) => {
        set(errors, path, message);
      });
    }

    try {
      const nextOrderHash = purchase.billing.address
        ? JSON.stringify({
            c: purchase.billing.address.country,
            r: purchase.billing.address.region,
          })
        : '';
      if (nextOrderHash !== orderHash) {
        setIsFetching(true);
        const nextPricing = await debouncedCalculateTotal({
          address: purchase.billing.address,
          numberOfCredits: purchase.numberOfCredits,
        });
        setPricing(nextPricing.values);
        setOrderHash(nextOrderHash);
        setIsFetching(false);
      }
    } catch (error) {
      errors.pricing = error.message;
    }

    return errors;
  };

  return profile && userBusiness ? (
    <StyledPayment>
      <Elements>
        <PaymentForm
          profile={profile}
          business={userBusiness}
          validate={validate}
          onSubmit={submitPayment}
          pricing={pricing}
          isFetching={isFetching}
        />
      </Elements>
      <PaymentSummary pricing={pricing} isFetching={isFetching} />
    </StyledPayment>
  ) : (
    <Loading />
  );
};

export default Payment;
