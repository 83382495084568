import React from 'react';
import styled from 'styled-components';
import { DateInput } from '@blueprintjs/datetime';
import { FormikTouched } from 'formik';
import { dateToTimestamp } from '../../../utilities/date';

const StyledFieldDate = styled(DateInput)`
  display: block;
  color: #32325d;
  background-color: white;
  border: 1px solid gainsboro;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px 12px;
  font-size: 13px;
  margin-top: 4px;

  .bp3-input {
    box-shadow: none;
  }
`;

interface FieldDateProps {
  name: string;
  title: string;
  value?: any;
  error?: string;
  onChange: (value: any) => void;
  setTouched: (touched: FormikTouched<any>) => void;
}

export const FieldDate: React.FunctionComponent<FieldDateProps> = ({
  name,
  title,
  value,
  onChange,
  error,
  setTouched,
}) => (
  <div>
    <label>{title}</label>
    <StyledFieldDate
      formatDate={(date: Date) => date.toLocaleString()}
      onChange={(date: Date) => {
        setTouched({ [name]: true });
        const nextTimestamp = dateToTimestamp(date);
        onChange(nextTimestamp);
      }}
      maxDate={new Date(7258136400000)}
      parseDate={str => new Date(str)}
      value={value && value.toDate()}
    />
    {error && <div className="form__error">{error}</div>}
  </div>
);
