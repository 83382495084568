import { IToastProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export const errorToast: IToastProps = {
  icon: IconNames.ISSUE,
  intent: Intent.WARNING,
  message: 'Error',
};

export const successToast: IToastProps = {
  icon: IconNames.TICK_CIRCLE,
  intent: Intent.SUCCESS,
  message: 'Success!',
};
