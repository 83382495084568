import React from 'react';

export const SubmitButton = ({
  label,
  disabled = false,
}: {
  label: string;
  disabled?: boolean;
}) => (
  <button type="submit" className="form__submit-button" disabled={disabled}>
    {label}
  </button>
);
