import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { AuthenticatedContent } from '@humancollective/human-dashboard';
import { StripeProvider } from 'react-stripe-elements';

import Payment from '../components/Payment';
import Navigation from '../components/Navigation';

const StyledPaymentPage = styled.div`
  width: 100%;
`;

const PaymentPage = () => (
  <StyledPaymentPage>
    <Helmet>
      <title>Purchase - Seedz</title>
    </Helmet>
    <Navigation />
    <Payment />
  </StyledPaymentPage>
);

const PaymentPageProviders: React.FunctionComponent = () => {
  const [stripeApi, setStripeApi] = React.useState(null);

  React.useEffect(() => {
    setStripeApi(window.Stripe(process.env.GATSBY_STRIPE_API_KEY));
  }, []);

  return (
    <StripeProvider stripe={stripeApi}>
      <AuthenticatedContent>
        <PaymentPage />
      </AuthenticatedContent>
    </StripeProvider>
  );
};

export default PaymentPageProviders;
