import React from 'react';
import { Address, Position } from '@humancollective/seedz-shared';
import { Button, Card } from '@blueprintjs/core';
import styled from 'styled-components';

import { UIContext } from '../../../contexts';
import { EditAddressModal } from '../../Modal/EditAddressModal';

const StyledFieldAddress = styled(Card)`
  margin-top: 24px;

  > label {
    margin-top: 0;
  }

  .field-address__value {
    font-size: 15px;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 12px;
  }
`;

interface FieldAddressProps {
  title: string;
  value?: Address;
  error?: string;
  onChange: (value: Address) => void;
  onChangePosition: (value: Position) => void;
}

export const FieldAddress: React.FunctionComponent<FieldAddressProps> = ({
  title,
  value,
  onChange,
  onChangePosition,
  error,
}) => {
  const ui = React.useContext(UIContext);

  const initialValues: Address = {
    country: '',
    streetAddress: '',
    locality: '',
    region: '',
    postalCode: '',
    ...value,
  };

  return (
    <StyledFieldAddress>
      <label>{title}</label>
      {value && (
        <address className="field-address__value">
          {value.streetAddress}
          <br />
          {value.locality}, {value.region} {value.postalCode}
        </address>
      )}
      <Button
        text="Change Address"
        onClick={() => {
          ui.modal.show({
            heading: 'Change Address',
            body: (
              <EditAddressModal
                initialValues={initialValues}
                onSubmit={(nextValue: {
                  address: Address;
                  position: Position;
                }) => {
                  onChange(nextValue.address);
                  if (onChangePosition) onChangePosition(nextValue.position);
                  ui.modal.dismiss();
                }}
              />
            ),
          });
        }}
      />
      {error && <div className="form__error">{error}</div>}
    </StyledFieldAddress>
  );
};
